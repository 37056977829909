import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { animateCSS } from '../../services/animate.css'
import * as Icon from 'react-feather'
import moment from 'moment'
import { hasResident, setResident, getResident, getHome, setHome, verifiedResident, parsePath, removeHome, downHome } from '../../services/home'
import { navigate, Link } from 'gatsby'
import { logs } from '../../utils'
import { isBrowser } from '../../services/auth'

import NavBar from './nav-bar'
import Settings from './settings'

// Some sweet animations
import 'animate.css'

const Home = ({ home, location, ...props }) => {
  const [loading, setLoading] = useState(0)

  const [username, setUsername] = useState('Vieras')
  // const [profile, setProfile] = useState(false)

  const [profile, setProfile] = useState(false)

  /*
   * Pop with helpers
   */
  const darkening = useRef(null)
  const notification = useRef(null)
  const [pop, setPop] = useState(false) // false for disabled
  const [_pop, _setPop] = useState(false)

  const [news, setNews] = useState([ // Peoples need read more Kalevala
    {
      id: -1,
      title: 'Ote ensimmäisestä runosta',
      date: '19.11.2021',
      timestamp: Math.floor(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)) / 1000),
      content: [
        'Mieleni minun tekevi, aivoni ajattelevi lähteäni laulamahan, saa\'ani sanelemahan, sukuvirttä suoltamahan, lajivirttä laulamahan. Sanat suussani sulavat, puhe\'et putoelevat, kielelleni kerkiävät, hampahilleni hajoovat.',
        'Veli kulta, veikkoseni, kaunis kasvinkumppalini! Lähe nyt kanssa laulamahan, saa kera sanelemahan yhtehen yhyttyämme, kahta\'alta käytyämme! Harvoin yhtehen yhymme, saamme toinen toisihimme näillä raukoilla rajoilla, poloisilla Pohjan mailla.',
        'Lyökämme käsi kätehen, sormet sormien lomahan, lauloaksemme hyviä, parahia pannaksemme, kuulla noien kultaisien, tietä mielitehtoisien, nuorisossa nousevassa, kansassa kasuavassa: noita saamia sanoja, virsiä virittämiä vyöltä vanhan Väinämöisen, alta ahjon Ilmarisen, päästä kalvan Kaukomielen, Joukahaisen jousen tiestä, Pohjan peltojen periltä, Kalevalan kankahilta.',
        'Niit\' ennen isoni lauloi kirvesvartta vuollessansa; niitä äitini opetti väätessänsä värttinätä, minun lasna lattialla eessä polven pyöriessä, maitopartana pahaisna, piimäsuuna pikkaraisna. Sampo ei puuttunut sanoja eikä Louhi luottehia: vanheni sanoihin sampo, katoi Louhi luottehisin, virsihin Vipunen kuoli, Lemminkäinen leikkilöihin.',
        'Viel\' on muitaki sanoja, ongelmoita oppimia: tieohesta tempomia, kanervoista katkomia, risukoista riipomia, vesoista vetelemiä, päästä heinän hieromia, raitiolta ratkomia, paimenessa käyessäni, lasna karjanlaitumilla, metisillä mättähillä, kultaisilla kunnahilla, mustan Muurikin jälessä, Kimmon kirjavan keralla.'
      ],
      status: 'flow'
    },
    {
      id: -2,
      title: 'Ote ensimmäisestä runosta',
      date: '19.11.2021',
      timestamp: Math.floor(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)) / 1000),
      content: [
        'Mieleni minun tekevi, aivoni ajattelevi lähteäni laulamahan, saa\'ani sanelemahan, sukuvirttä suoltamahan, lajivirttä laulamahan. Sanat suussani sulavat, puhe\'et putoelevat, kielelleni kerkiävät, hampahilleni hajoovat.',
        'Veli kulta, veikkoseni, kaunis kasvinkumppalini! Lähe nyt kanssa laulamahan, saa kera sanelemahan yhtehen yhyttyämme, kahta\'alta käytyämme! Harvoin yhtehen yhymme, saamme toinen toisihimme näillä raukoilla rajoilla, poloisilla Pohjan mailla.',
        'Lyökämme käsi kätehen, sormet sormien lomahan, lauloaksemme hyviä, parahia pannaksemme, kuulla noien kultaisien, tietä mielitehtoisien, nuorisossa nousevassa, kansassa kasuavassa: noita saamia sanoja, virsiä virittämiä vyöltä vanhan Väinämöisen, alta ahjon Ilmarisen, päästä kalvan Kaukomielen, Joukahaisen jousen tiestä, Pohjan peltojen periltä, Kalevalan kankahilta.',
        'Niit\' ennen isoni lauloi kirvesvartta vuollessansa; niitä äitini opetti väätessänsä värttinätä, minun lasna lattialla eessä polven pyöriessä, maitopartana pahaisna, piimäsuuna pikkaraisna. Sampo ei puuttunut sanoja eikä Louhi luottehia: vanheni sanoihin sampo, katoi Louhi luottehisin, virsihin Vipunen kuoli, Lemminkäinen leikkilöihin.',
        'Viel\' on muitaki sanoja, ongelmoita oppimia: tieohesta tempomia, kanervoista katkomia, risukoista riipomia, vesoista vetelemiä, päästä heinän hieromia, raitiolta ratkomia, paimenessa käyessäni, lasna karjanlaitumilla, metisillä mättähillä, kultaisilla kunnahilla, mustan Muurikin jälessä, Kimmon kirjavan keralla.'
      ],
      status: 'flow'
    }
  ])

  // Lock screen, if pop is shown
  useEffect(() => {
    // Scroll and darkening
    if (pop) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
      if (darkening.current) {
        animateCSS(darkening.current, 'fadeOut')
      }
    }

    if (pop && pop !== _pop) {
      if (_pop) {
        if (notification.current) {
          animateCSS(notification.current, 'slideOutDown').then(() => {
            _setPop(pop)
            animateCSS(notification.current, 'slideInUp')
          })
        } else {
          _setPop(pop)
        }
      } else {
        _setPop(pop)
        if (notification.current) {
          animateCSS(notification.current, 'slideInUp')
        }
      }
    }

    if (!pop) {
      if (notification.current) {
        animateCSS(notification.current, 'slideOutDown').then(() => {
          _setPop(false)
        })
      }
    }
  }, [pop, _pop, darkening, notification])

  useEffect(() => {
    profile.email && setUsername(profile.email.split('@')[0].split('.')[0])
  }, [profile])

  /*
   * Load news or settings
   */

  const loadNews = useCallback(
    () => {
      const token = getResident()

      if (!token) {
        setPop('hello')
        return
      }

      setLoading(prev => prev + 1)

      const requestOptions = {
        headers: { Authorization: `Bearer ${token}` }
      }
      window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/homes/${home}`, requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status === 'success') {
              if (result.news) {
                setNews(result.news)
                setHome(home, result.news)
              }
            }

            if (result.status === 'not_found') {
              setPop('bad-url')
            }
          },
          (error) => {
            console.error(error)
          }
        ).then(
          () => {
            setLoading(prev => prev - 1)
          }
        )
    },
    [home]
  )

  const loadProfile = useCallback(
    () => {
      const profile = getHome('settings')
      if (profile) setProfile(profile)

      const token = getResident()

      if (!token) {
        setPop('hello')
        return
      }

      if (!verifiedResident()) return

      setLoading(prev => prev + 1)

      const requestOptions = {
        headers: { Authorization: `Bearer ${token}` }
      }
      window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/residents/me`, requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.status === 'success') {
              if (result.profile) {
                setProfile(result.profile)
                setHome('settings', result.profile)
              }
            }
          },
          (error) => {
            logs && console.error(error)
          }
        ).then(
          () => {
            setLoading(prev => prev - 1)
          }
        )
    },
    []
  )

  /*
   * Check does user exists, and home is set
   */

  useEffect(() => {
    // If `home` not set, navigate to index
    if (!home) {
      logs && console.log('Home bad url')
      setPop('bad-url')
      return
    }

    const profile = getHome('settings')
    if (profile) {
      setProfile(profile)
    } else {
      home !== 'settings' && loadProfile()
    }

    if (home === 'settings' && hasResident()) {
      loadProfile()
      return
    }

    if (hasResident()) {
      logs && console.log('Browser has resident')

      const news = getHome(home)
      if (news) {
        logs && console.log('Found news, from cache')
        setNews(news)
      }

      logs && console.log('Loading news...')
      loadNews()
      return
    }

    setPop('hello')
  }, [home, loadNews, loadProfile])

  /*
   * Handlers
   */

  // Handle email submit
  const handleSubmit = useCallback(event => {
    event.preventDefault()
    setLoading(prev => prev + 1)
    const data = new window.FormData(event.target)

    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/residents/${data.get('email')}`)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status === 'success') {
            logs && console.log('Home response success', result)

            setPop(false)
            setProfile({
              email: data.get('email'),
              lastSeen: false,
              verified: false,
              homes: []
            })
            result.token && setResident(result.token)

            setHome('settings', {
              email: data.get('email'),
              lastSeen: false,
              verified: false,
              homes: []
            })
            loadNews()
          }
        },
        (error) => {
          logs && console.error('Home mail failed', error)
        }
      ).then(
        () => {
          setLoading(prev => prev - 1)
        }
      )
  }, [loadNews])

  // Handle confirm submit
  const handleConfirm = useCallback(event => {
    event.preventDefault()

    const token = getResident()

    if (!token) {
      setPop('hello')
      return
    }

    setLoading(prev => prev + 1)
    const data = new window.FormData(event.target)

    const requestOptions = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'POST',
      body: data
    }
    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/residents/me`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status === 'success') {
            logs && console.log('Confirmation response success', result)

            setPop('confirm-success')
            result.token && setResident(result.token)

            loadProfile()
            loadNews()
          }
        },
        (error) => {
          logs && console.error('Confirmation failed', error)
        }
      ).then(
        () => {
          setLoading(prev => prev - 1)
        }
      )
  }, [loadProfile, loadNews])

  // Handle unsubscribe submit
  const handleUnsubscribe = useCallback(home => {
    const token = getResident()

    if (!token) {
      setPop('hello')
      return
    }

    const data = new window.FormData()
    data.set('id', home.id)

    setLoading(prev => prev + 1)

    const requestOptions = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'DELETE',
      body: data
    }

    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/residents/me/unsubscribe`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status === 'success') {
            removeHome(home.path)
            loadProfile()
            setPop(false)
          }
        },
        (error) => {
          logs && console.error('Confirmation failed', error)
        }
      ).then(
        () => {
          setLoading(prev => prev - 1)
        }
      )
  }, [loadProfile])

  // Handle delete resident submit
  const handleDelete = useCallback(home => {
    const token = getResident()

    if (!token) {
      setPop('hello')
      return
    }

    setLoading(prev => prev + 1)

    const requestOptions = {
      headers: { Authorization: `Bearer ${token}` },
      method: 'DELETE'
    }

    window.fetch(`${process.env.REACT_APP_EXTENSION_API_ENDPOINT}/v1/residents/me`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.status === 'success') {
            downHome()
            setPop('hello')
          }
        },
        (error) => {
          logs && console.error('Confirmation failed', error)
        }
      ).then(
        () => {
          setLoading(prev => prev - 1)
        }
      )
  }, [])

  /*
   * Memo elements
   */

  // Settings
  const settings = useMemo(() => (
    <Settings setPop={v => setPop(v)} profile={profile} location={location} />
  )
  , [setPop, profile, location])

  // Nav-bar
  const navBar = useMemo(() => (
    <NavBar page={home} username={username} />
  )
  , [home, username])

  // Pop element
  const popEl = useMemo(() => {
    if (!_pop) return null

    const pop = typeof _pop === 'object' && _pop.pop ? _pop.pop : _pop

    return pop
      ? (
        <>
          <div className='container'>

            {/* Bad url pop, if not home set */}
            {pop === 'bad-url' && (
              <div
                className='bg-white w-full mx-auto p-4 sm:p-8 xl:p-12 rounded-t shadow \
                flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8'
              >
                <Icon.Compass className='h-auto w-16 sm:w-12 text-current animate__animated animate__rotate animate__infinite' />
                <div className='w-full'>
                  <h2 className='text-xl'>Osoite on virheellinen</h2>
                  <p>Valitettavasti, tämä osoite on virheellinen tai ei enää käytössä.</p>
                  <div className='flex gap-4 mt-4 flex-wrap'>
                    {hasResident() && (
                      <button
                        className='btn -green flex-shrink-0'
                        onClick={() => {
                          setPop(false)
                          navigate('/home/settings')
                        }}
                      >
                        Asetuksiin
                      </button>
                    )}
                    <Link
                      className='btn flex gap-4 items-center font-bold flex-shrink-0'
                      to='/'
                    >
                      Opasti etusivulle <Icon.ArrowRight className='h-auto w-5 text-current ' />
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {/* Hello pop, for sign up or in */}
            {pop === 'hello' && (
              <div className='bg-white w-full mx-auto p-4 sm:p-8 xl:p-12 rounded-t shadow'>
                <p>Tervetuloa tilaamaan taloyhtiön tiedotteet. Palvelua kehitetään koko ajan, ja tulevaisuudessa tulet saamaan tiedotteet sähköpostiisi noin kerran viikossa (ja näet ne myös netissä), jos uusia tiedotteita on tullut. Palvelu on maksuton, ja sen voi sulkea tai peruuttaa milloin vain.</p>
                <form
                  className='flex flex-col gap-3 mt-3'
                  onSubmit={event => handleSubmit(event)}
                >

                  {/*
                    * Accessibility, screen reader will read this notice first.
                    * But for all /seers/ will see it as last element, thanks to `order-last`.
                    */}
                  <p
                    className='my-0 text-sm order-last'
                  >
                    Palvelun tarjoaa Klinen, ja sitä ylläpidetään Opasti nimellä. Rekisteriselosteen pääset lukemaan Klinenin sivuilta <a href='https://www.klinen.fi/rekisteriseloste'>tästä</a>.
                  </p>

                  <label className='-group'>
                    Sähköpostisi
                    <input
                      type='email'
                      name='email'
                      onChange={e => {
                        setUsername(e.target.value.split('@')[0].split('.')[0])
                      }}
                      required
                    />
                  </label>

                  <button type='submit' className='btn -green'>Tiedotteisiin</button>
                </form>
              </div>
            )}

            {/* Confirm pop, for confirming email */}
            {pop === 'confirm' && (
              <div className='bg-white w-full mx-auto p-4 sm:p-8 xl:p-12 rounded-t shadow'>
                <p>Vahvista sähköpostisi vahvistukoodilla, jonka lähetimme antamaasi sähköpostiin. Tarkista myös roskaposti-kansio, mikäli viestiä ei näy saapuneissa. Koodi on kuusi numeroinen.</p>
                <form
                  className='flex flex-col gap-3 mt-3'
                  onSubmit={event => handleConfirm(event)}
                >

                  <label className='-group'>
                    Vahvistuskoodi
                    <input name='verification_code' type='number' min='100000' max='999999' length='6' className='appearance-none' required />
                  </label>

                  <button type='submit' className='btn -primary'>Tiedotteisiin</button>
                </form>
              </div>
            )}

            {/* Confirmation success pop */}
            {pop === 'confirm-success' && (
              <div
                className='bg-green-600 text-white w-full mx-auto p-4 sm:p-8 xl:p-12 rounded-t shadow \
                flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8'
              >
                <Icon.ThumbsUp className='h-auto w-16 sm:w-12 text-current' />
                <div className='w-full'>
                  <h2 className='text-xl'>Vahvistus onnistui</h2>
                  <p>Voit nyt hallita tilauksiasi ja nähdä muut taloyhtiöt, jotka olet tilannut.</p>
                  <button
                    className='flex gap-4 items-center py-4 font-bold'
                    onClick={() => setPop(false)}
                  >
                    Tiedotteisiin <Icon.ArrowRight className='h-auto w-6 text-current' />
                  </button>
                </div>
              </div>
            )}

            {/* Delete account pop */}
            {pop === 'delete-account' && (
              <div
                className='bg-white w-full mx-auto p-4 sm:p-8 xl:p-12 rounded-t shadow \
                flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8'
              >
                <Icon.Trash2 className='h-auto w-16 sm:w-12 text-red-600' />
                <div className='w-full'>
                  <h2 className='text-xl'>Poista tilisi</h2>
                  <p>Toimintoa ei voi perua, mutta voit luoda uuden tilin milloin vain haluat.</p>
                  <div className='flex gap-4 mt-4 flex-wrap'>
                    <button
                      className='btn -red flex-shrink-0'
                      onClick={() => handleDelete()}
                    >
                      Poista tili
                    </button>
                    <button
                      className='btn flex gap-4 items-center font-bold flex-shrink-0'
                      onClick={() => setPop(false)}
                    >
                      Takaisin <Icon.ArrowRight className='h-auto w-5 text-current ' />
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Unsubscribe from house news pop */}
            {pop === 'unsubscribe' && (
              <div
                className='bg-white w-full mx-auto p-4 sm:p-8 xl:p-12 rounded-t shadow \
                flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8'
              >
                <Icon.LogOut className='h-auto w-16 sm:w-12 text-red-600' />
                <div className='w-full'>
                  <span className='capitalize text-xl text-black'>{parsePath(_pop.data.path)}</span>
                  <h2 className='text-xl'>Poistu taloyhtiön tiedotelistalta</h2>
                  <p>Et saa enää tiedotteita sähköpostilla, etkä näe taloyhtiön tiedotteita netissä. Voit tilata tiedotteet milloin haluat uudelleen taloyhtiön linkistä.</p>
                  <div className='flex gap-4 mt-4 flex-wrap'>
                    <button
                      className='btn -red flex-shrink-0'
                      onClick={() => handleUnsubscribe(_pop.data)}
                    >
                      Poistu listalta
                    </button>
                    <button
                      className='btn flex gap-4 items-center font-bold flex-shrink-0'
                      onClick={() => setPop(false)}
                    >
                      Haluan pysyä listalla <Icon.ArrowRight className='h-auto w-5 text-current' />
                    </button>
                  </div>
                </div>
              </div>
            )}

          </div>
        </>
      )
      : null
  }
  , [_pop, handleConfirm, handleUnsubscribe, handleDelete, handleSubmit])

  // News
  const newsletter = useMemo(
    () => news.map(letter => (
      <div
        key={letter.id}
        className='px-4 md:px-6 w-full'
        aria-hidden={
          // If connect is just for visuals
          letter.status === 'flow'
        }
      >
        <div
          className={
            '-mx-4 md:-mx-6 shadow p-6 md:p-8 flex flex-col gap-4 md:gap-6 bg-white rounded relative' +
            // Use flow font, if placeholder
            (letter.status === 'flow' ? ' font-flow' : '')
          }
        >
          <div className='flex flex-col gap-1'>

            {/* If timestamp exists */}
            {letter.timestamp && isBrowser() && (
              <span
                title={new Date(letter.timestamp * 1000).toLocaleString()}
                className={
                  'uppercase tracking-wide font-bold' +
                  // Use flow font, if placeholder
                  (letter.status === 'flow' ? ' text-2xl leading-3 text-gray-500' : ' text-sm text-gray-600')
                }
              >
                Julkaistu {moment(new Date(letter.timestamp * 1000)).locale(window.navigator.userLanguage || window.navigator.language).fromNow()}
              </span>
            )}

            {/* If timestamp does not exists, but date exists */}
            {!letter.timestamp && letter.date && (
              <span
                className={
                  'uppercase tracking-wide font-bold' +
                  // Use flow font, if placeholder
                  (letter.status === 'flow' ? ' text-2xl leading-3 text-gray-500' : ' text-sm text-gray-600')
                }
              >
                Julkaistu {letter.date}
              </span>
            )}

            {letter.title &&
              <h2
                className={
                  'm-0' + // Use flow font, if placeholder
                  (letter.status === 'flow' ? ' font-flow text-4xl' : '')
                }
              >
                {letter.title}
              </h2>}
          </div>

          {/* Render paragraphs */}
          {letter.content && letter.content.map((p, index) => (
            <p
              key={`${letter.id}-${index}`}
              className={
                'my-0' + // Use flow font, if placeholder
                (letter.status === 'flow' ? ' font-flow text-2xl leading-4' : '')
              }
            >
              {p}
            </p>
          ))}

          {/* If status `confirm` show milkglass effect */}
          {letter.status === 'confirm' && <div className='bg-gradient-to-t from-white absolute w-full h-full bottom-0 left-0 rounded' />}
        </div>

        {/* Display notification under letter, if desired */}
        {letter.status === 'confirm' && (
          <div className='bg-yellow-400 flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8 text-white rounded-b shadow w-full'>
            <Icon.AlertCircle className='h-auto w-16 sm:w-12 text-current' />
            <div className='w-full'>
              <h2 className='text-xl'>Vahvista sähköpostisi</h2>
              <p>Näet kaikki tiedotteet, kun olet vahvistanut sähköpostisi.</p>
              <button className='flex gap-4 items-center py-4 font-bold' onClick={() => setPop('confirm')}>Vahvistamaan <Icon.ArrowRight className='h-auto w-6 text-current' /></button>
            </div>
          </div>
        )}

      </div>
    )), [news])

  return (
    <>
      <div
        className={
          'fixed top-0 flex w-full h-3 top-0 left-0 z-30 animate__animated shadow' +
          (loading > 0 ? ' animate__fadeIn' : ' animate__fadeOut')
        }
      >
        <span className='w-full h-full bg-blue-500 bg-gradient-to-r from-pink-400 via-indigo-500 to-green-400 animate-gradient-x' />
      </div>

      {navBar}

      {profile && !profile.verified && (
        <div
          className='container flex flex-col items-center gap-6 md:gap-8 print:hidden'
        >
          <div className='bg-yellow-400 flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8 text-white rounded shadow w-full'>
            <Icon.AlertCircle className='h-auto w-16 sm:w-12 text-current' />
            <div className='w-full'>
              <h2 className='text-xl'>Vahvista sähköpostisi</h2>
              <p>Jotta näet kaikki tietosi ja kokonaiset tiedotteet, vahvista sähköpostisi sinne tulleella vahvistuskoodilla. Jos viestiä ei näy, tarkista roskaposti-kansio.</p>
              <button className='flex gap-4 items-center py-4 font-bold' onClick={() => setPop('confirm')}>Vahvistamaan <Icon.ArrowRight className='h-auto w-6 text-current' /></button>
            </div>
          </div>
        </div>
      )}

      {home === 'settings'
        ? settings
        : (
          <>
            <div
              className='container flex flex-col items-center gap-6 md:gap-8'
            >
              <div className='w-full max-w-4xl flex hidden'>
                <div className='w-full bg-blue-400 relative m-auto' style={{ paddingTop: '50%' }}>
                  <span
                    className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-bold text-white text-2xl'
                  >
                    Mainos<br />2:1
                  </span>
                </div>
              </div>
              <div className='w-full flex gap-8 items-center overflow-x-auto overflow-y-hidden py-2'>
                <h1 className='m-0 text-2xl capitalize flex-shrink-0'>{home && parsePath(home)}</h1>
                {profile && profile.homes.map((other, i) => home !== other.path && (
                  <Link
                    key={`${other.id}:${i}`}
                    to={`/home/${other.path}`}
                    className='m-0 text-xl text-gray-900 visited:text-gray-900 capitalize opacity-75 hover:text-current hover:opacity-100 transition flex-shrink-0'
                  >
                    {other.path && parsePath(other.path)}
                  </Link>
                ))}
              </div>
              {
                news.length > 0
                  ? newsletter
                  : (
                    <div
                      className='max-w-screen-md mx-auto p-4 sm:p-8 xl:p-12 \
                      flex gap-6 md:gap-8 flex-col sm:flex-row items-center sm:justify-between p-6 md:p-8'
                    >
                      <Icon.Search className='h-auto w-16 sm:w-12 text-current animate__animated animate__tada animate__slower animate__delay-3s' />
                      <div className='w-full'>
                        <h2 className='text-xl'>Edes suurennuslasi ei löytänyt tiedotteita</h2>
                        <p>... taloyhtiö ei ole julkaissut yhtään tiedotetta, joten sivu on tyhjä.</p>
                      </div>
                    </div>
                  )
              }
            </div>
          </>
        )}

      {(pop || _pop) && (
        <>
          <div
            ref={darkening}
            className='fixed top-0 left-0 w-0 h-0 z-10 w-full h-full bg-black bg-opacity-50 animate__animated animate__fadeIn'
          />
          <div
            ref={notification}
            className='fixed top-0 left-0 w-full h-full z-20 flex justify-center items-end'
          >
            {popEl}
          </div>
        </>
      )}
    </>
  )
}

export default Home
