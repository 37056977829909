import { isBrowser } from './auth'
import jwtDecode from 'jwt-decode'

export const getResident = () => {
  if (isBrowser()) {
    const token = window.localStorage.getItem('resident')
    if (token && jwtDecode(token).exp > ~~(Date.now() / 1000)) return token
  }
  return false
}

export const verifiedResident = () => {
  const token = getResident()
  if (!token) return false
  return jwtDecode(token).verified
}

export const setResident = token =>
  window.localStorage.setItem('resident', token)

export const hasResident = () => {
  const home = getResident()
  return !!home
}

export const removeResident = () => window.localStorage.removeItem('resident')

export const getHome = home =>
  isBrowser() && window.localStorage.getItem(`home:${home}`) && JSON.parse(window.localStorage.getItem(`home:${home}`))
    ? JSON.parse(window.localStorage.getItem(`home:${home}`))
    : false

export const setHome = (home, news) =>
  window.localStorage.setItem(`home:${home}`, JSON.stringify(news))

export const removeHome = home => window.localStorage.removeItem(`home:${home}`)

export const removeHomes = () => {
  for (const key in window.localStorage) {
    if (key.substring(0, 5) === 'home:') window.localStorage.removeItem(key)
  }
}

export const downHome = () => {
  removeHomes()
  removeResident()
}

export const parsePath = path => path.split('.')[0].replace(/-/g, ' ')
