import React from 'react'
import { Link, navigate } from 'gatsby'

const NavBar = ({ username, page, ...props }) => {
  return (
    <header
      className='container flex items-center justify-between py-6 print:hidden'
    >
      <div className='flex flex-col'>
        <span>Tervehdys 👋</span>
        <span className='bold font-bold text-lg capitalize'>{username || 'vieras'}</span>
      </div>
      {page === 'settings'
        ? (
          <button
            onClick={() => navigate(-1)}
            className='btn -primary rounded-full text-sm tracking-wide'
          >
            Takaisin
          </button>
        )
        : (
          <Link
            to='/home/settings'
            className='btn -green rounded-full text-sm tracking-wide'
          >
            Asetukset
          </Link>
        )}

    </header>
  )
}

export default NavBar
