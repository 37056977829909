import React from 'react'
import { Link } from 'gatsby'
import logo from '../../images/logo.min.svg'

export default function Footer () {
  return (
    <footer
      className='container bg-opasti-white py-6 print:hidden'
    >
      <Link to='/'>
        <span className='sr-only'>Opasti</span>
        <img className='h-9' alt='' src={logo} />
      </Link>
    </footer>
  )
}
