import React, { } from 'react'
import * as Icon from 'react-feather'
import { Link, navigate } from 'gatsby'
import moment from 'moment'
import { downHome, parsePath } from '../../services/home'
import { isBrowser } from '../../services/auth'

const Settings = ({ setPop, profile, location, ...props }) => {
  if (!profile) {
    return null
  }
  return (
    <>
      <div
        className='container flex flex-col gap-3 md:gap-4'
      >
        <h1 className='print:hidden'>Asetukset ja omat tiedot</h1>
        <p className='print:hidden'>
          Täällä näet kaikki taloyhtiöt, joista olet tilannut tiedotteet.
          Voit halutessasi lopettaa tilauksen taloyhtiöstä.
          Halutessasi voit tulostaa myös kaikki omat tietosi tai poistaa tietosi kokonaan järjestelmästä.
        </p>
        <p className='hidden print:block'>
          Tiedot Opasti taloyhtiö tilauksista sähköpostilta {profile.email}.
        </p>
        <p className='hidden print:block'>
          Tiedot tulostettu {new Date().toLocaleString()}.
        </p>
        <div className='flex gap-3 justify-between items-center flex-wrap print:hidden'>
          <h2 className='m-0'>Haluatko kirjautua ulos?</h2>
          <button
            onClick={() => {
              downHome()
              navigate('/')
            }}
            className='flex justify-between gap-4 items-center p-4 px-6 font-semibold rounded shadow-sm transition-all duration-500 text-lg bg-size-200 bg-gradient-to-bl bg-pos-0 from-yellow-300 via-red-400 to-red-500 text-white hover:shadow hover:bg-pos-0-100'
          >
            <Icon.LogOut className='h-auto w-7 text-current' />
            <span>Kirjaudu ulos</span>
          </button>
        </div>
        <h2>Tilatut taloyhtiöt</h2>
        <ul className='p-0 flex flex-col gap-6'>
          {profile.homes && profile.homes.map(home => (
            <ol
              key={home.id}
              className='p-4 sm:p-8 xl:p-12 bg-white shadow rounded-md flex flex-col lg:flex-row lg:justify-between gap-6'
            >
              <div className='flex flex-col gap-6'>
                <h3 className='m-0 capitalize'>{parsePath(home.path)}</h3>
                <div className='flex flex-col gap-1'>
                  Linkki taloyhtiöön on:
                  <Link to={`/home/${home.path}`}>{location.origin}/home/{home.path}</Link>
                </div>
              </div>
              <div className='flex gap-4 items-center flex-wrap print:hidden'>
                <Link to={`/home/${home.path}`} className='btn -primary flex-shrink-0'>Tiedotteet</Link>
                <button className='btn -green flex-shrink-0 hidden'>Viimeisin kirje</button>
                <button className='btn -red flex-shrink-0' onClick={() => setPop({ pop: 'unsubscribe', data: { ...home } })}>Lopeta tilaus</button>
              </div>
            </ol>
          ))}
        </ul>
        <h2>Tietosi</h2>
        <table>
          <tbody>
            <tr className='border-b-2'>
              <td className='p-2'>Sähköpostiosoite</td>
              <td className='p-2'>{profile.email}</td>
            </tr>
            <tr className='border-b-2'>
              <td className='p-2'>Nähty viimeksi</td>
              <td className='p-2 flex flex-col gap-0'>
                {isBrowser() && profile.last_seen
                  ? (
                    <>
                      <span className='print:hidden'>
                        {moment.utc(profile.last_seen).local().fromNow()}
                      </span>
                      <span className='text-sm text-gray-600 uppercase tracking-wide font-bold'>
                        {moment.utc(profile.last_seen).local().toString()}
                      </span>
                    </>
                  )
                  : (
                    <p>Tietoa ei ole</p>
                  )}
              </td>
            </tr>
            <tr className='border-b-2'>
              <td className='p-2'>Vahvistettu viimeksi</td>
              <td className='p-2 flex flex-col gap-0'>
                {isBrowser() && profile.verified
                  ? (
                    <>
                      <span className='print:hidden'>
                        {moment.utc(profile.verified).local().fromNow()}
                      </span>
                      <span className='text-sm text-gray-600 uppercase tracking-wide font-bold'>
                        {moment.utc(profile.verified).local().toString()}
                      </span>
                    </>
                  )
                  : (
                    <p>Tietoa ei ole</p>
                  )}
              </td>
            </tr>
          </tbody>
        </table>
        <p className='print:hidden'>Voit halutessasi tulostaa kaikki tietosi.</p>
        <button className='btn md:max-w-max print:hidden' onClick={() => window.print()}>Tulosta tiedot</button>
        <h2 className='print:hidden'>Tilin poistaminen</h2>
        <p className='print:hidden'>Tilisi poistetaan automaattisesti, kun et tilaa yhtään taloyhtiötä. Voit myös halutessasi poistaa tilin alla olevasta napista. Huomaathan ettei poistoa voida perua. Kun olet poistanut tilisi kaikki henkilötietosi poistetaan tietokannasta.</p>
        <p className='print:hidden'>Olet aina tervetullut takaisin, ja voit luoda tilin uudelleen milloin vain haluat.</p>
        <button className='btn -red md:max-w-max print:hidden' onClick={() => setPop('delete-account')}>Poista tili</button>
      </div>
    </>
  )
}

export default Settings
