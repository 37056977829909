import React, { } from 'react'

import Footer from './footer'

const Layout = ({ children, ...props }) => {
  return (
    <main className='text-gray-900 font-medium flex flex-col justify-between gap-8 min-h-screen'>
      {children}
      <Footer />
    </main>
  )
}

export default Layout
