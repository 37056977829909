import { Router } from '@reach/router'
import React, { } from 'react'
import Layout from '../components/home/layout'
import Index from '../components/home/index'

// Fonts by fontsource

// heading font
import '@fontsource/nunito/600.css'
import '@fontsource/nunito/700.css'

// body font
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'

// loading font
import '@fontsource/flow-rounded'

const Home = ({ ...props }) => {
  return (
    <Layout {...props}>
      <Router className='flex flex-col justify-between gap-8'>
        <Index path='/home' />
        <Index path='/home/:home' />
      </Router>
    </Layout>
  )
}

export default Home
